import React from "react";

import { SammenligningTemplate } from "./index";
import {
  ArbeidsmarkedYrkeElement,
  ArbeidsmarkedUtdanningElement,
} from "../../data/ApiTypes";
import LonnWrapper from "../visualizations/Lonn/LonnWrapper";
import ArbeidsledighetWrapper from "../visualizations/Arbeidsledighet/ArbeidsledighetWrapper";
import { TranslateString } from "../app/Translate";
import EntreprenorskapWrapper from "../visualizations/Entreprenorskap/EntreprenorskapWrapper";
import SektorWrapper from "../visualizations/Sektor/SektorWrapper";
import LenkeVisualizationWrapper from "../visualizations/Lenke/LenkeVisualizationWrapper";
import ComparisonPageVisualization from "../ui/ComparisonPageVisualization";

const Yrke: SammenligningTemplate[] = [
  {
    title: "Lønnsstatistikk",
    widget_id: "lonn",
    path: "/sammenligning/lonn",
    // query: { sektor: "A" },
    Component: LonnWrapper,
  },
  {
    title: "Arbeidsledighet",
    widget_id: "arbeidsledighet",
    path: "/sammenligning/arbeidsledighet",
    Component: ArbeidsledighetWrapper,
  },

  {
    title: "Arbeidsmarked",
    description: TranslateString(
      "De som har en utdanning som er ment å kvalifisere for dette yrket har følgende yrker. Hvis det ikke er vanlig med en bestemt utdanning for å få dette yrket, vil det ofte ikke bli vist noen tall. Kilde SSB 2022"
    ),
    widget_id: "arbeidsmarkedUtdanning",
    path: "/sammenligning/utdanning2yrke",
    render: (
      element: ArbeidsmarkedUtdanningElement[],
      layout,
      disaggregate,
      nus_kortnavn
    ) => {
      // return <Visualization unoId={element} direction="yrke2utdanning" limit={5} />
      return (
        <React.Fragment>
          <ComparisonPageVisualization
            uno_id={element}
            direction="uno_id2styrk08"
            layout={layout}
            disaggregate={disaggregate}
            nus_kortnavn={nus_kortnavn}
          />
        </React.Fragment>
      );
      // return <VizChartWrapper uno_id={element} direction="utdanning2yrke" />
    },
  },
  {
    title: "Utdanningsbakgrunn",
    description: TranslateString("Hva er de vanligste utdanningene for de som har dette yrket? Kilde: SSB. Tall fra 2023"),
    widget_id: "arbeidsmarkedYrke",
    path: "/sammenligning/yrke2utdanning",
    render: (
      element: ArbeidsmarkedYrkeElement[],
      layout,
      disaggregate,
      nus_kortnavn
    ) => {
      // return <Visualization
      //   unoId={element}
      //   limit={8}
      //   layout="bars"
      //   direction="yrke2utdanning"
      // />
      // return <ComparisonPageVisualization uno_id={element} direction="yrke2utdanning" />
      return (
        <ComparisonPageVisualization
          uno_id={element}
          direction="uno_id2nus"
          layout={layout}
          disaggregate={disaggregate}
          nus_kortnavn={nus_kortnavn}
        />
      );
    },
  },
  {
    title: "Selvstendig næringsdrivende",
    widget_id: "entrepenorskap",
    path: "/sammenligning/entrepenorskap",
    Component: EntreprenorskapWrapper,
  },
  {
    title: "Sektorer",
    widget_id: "sektor",
    path: "/sammenligning/main",
    Component: SektorWrapper,
    // render: (element: MainElement) => {
    //   const SektorConfig = {
    //     values: [
    //       {
    //         label: TranslateString("Ikke i arbeid"),
    //         value: "sektor_antall_ikkearbeid",
    //       },
    //       { label: TranslateString("Privat"), value: "sektor_antall_privat" },
    //       {
    //         label: TranslateString("Offentlig"),
    //         value: "sektor_antall_offentlig",
    //       },
    //       {
    //         label: TranslateString("Arbeidsledig"),
    //         value: "sektor_antall_arbeidsledig",
    //       },
    //       {
    //         label: TranslateString("I utdanning"),
    //         value: "sektor_antall_iutdanning",
    //       },
    //       {
    //         label: TranslateString("Selvstendig næringsdriver"),
    //         value: "sektor_antall_selvstendig",
    //       },
    //     ],
    //     chartType: "bar",
    //   };

    //   let data = SektorConfig.values.map(s => {
    //     return (element as any)[s.value];
    //   });
    //   if (data.every(d => !d)) return <NoData />;
    //   return SektorConfig.chartType === "pie" ? (
    //     <PieChart values={SektorConfig.values} element={element} />
    //   ) : (
    //       <BarChart values={SektorConfig.values} element={element} />
    //     );
    // },
  },
  {
    title: "Lenke",
    widget_id: "lenke",
    path: "/sammenligning/main",
    Component: LenkeVisualizationWrapper,
  },
];
export default Yrke;
