import React, { Component } from "react";
import styles from "./ResetButton.module.scss";
import Translate from "../app/Translate";

type Props = {
  selected_uno_id: string[];
  toggleuno: Function;
  resetInterests: Function;
  resetNiva: Function;
  resetCheckBoxState: Function;
};

export default class InnHoldButton extends Component<Props> {
  handleClick = () => {
    for (let i = 0; i < this.props.selected_uno_id.length; i++) {
      this.props.toggleuno(this.props.selected_uno_id[i]);
    }
    this.props.resetInterests();
    this.props.resetNiva();
    this.props.resetCheckBoxState();
  };

  render() {
    return this.props.selected_uno_id.length ? (
      <div className={`${styles.container}`}>
        <button
          aria-label="Nullstill"
          className={`${styles.container_button}`}
          onClick={this.handleClick}
        >
          <span>
            <Translate nb="Nullstill" />
          </span>
          {/*<span>
                        <Times />
                    </span>*/}
        </button>
      </div>
    ) : null;
  }
}
