import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { getLang } from "../../app/Translate";
import ErrorModal from "./ErrorModal";
import InfoPane from "../Shared/InfoPane";
import { infoPaneText } from "../../../util/Constants";
//import './PageChrome.css'

type Props = {
  children?: React.ReactNode;
};

class PageChrome extends PureComponent<Props> {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };
  render() {
    return (
      <div lang={getLang()}>
        <InfoPane description={infoPaneText} />
        {/*<Header />*/}
        {/* <SecondHeader /> */}
        {this.props.children}
        {/* <Footer /> */}
        <ErrorModal />
      </div>
    );
  }
}

export default PageChrome;
