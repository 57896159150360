import React, { Component, ReactNode } from "react";
import { UtdanningLonnConfig } from "./UtdanningLonnConfig";
import Translate from "../../app/Translate";
import ClickOutsideListener from "../../utils/ClickOutsideListner";
import styles from "../Shared/VisualizationFilterHeader.module.scss";
import UtdanningLonnHeaderFilters from "./UtdanningLonnHeaderFilters";
import CloseIcon2 from "../Generic/CloseIcon2";

type Props = {
  config: UtdanningLonnConfig;
  setConfig: (config: UtdanningLonnConfig) => void;
  onFilterClicked: (event: any, key: string) => void;
  widget: boolean;
  date?: string | null;
  source?: string;
  children?: ReactNode;
};

type State = {
  open: boolean;
};

const LonnTranslations = {
  Brutto: <Translate nb="Lønn" />,
  "Med overtid": <Translate nb="Avtalt lønn" />,
};
const StatistiskMålTranslations = {
  Median: <Translate nb="Median" />,
  Gjennomsnitt: <Translate nb="Gjennomsnitt" />,
  "Median og kvartiler": <Translate nb="Median og kvartiler" />,
};

const TidsenhetTranslations = {
  Årlig: <Translate nb="Årlig" />,
  Månedlig: <Translate nb="Månedlig" />,
  "Ca. timelønn": <Translate nb="Ca. timelønn" />,
};

class UtdanningLonnHeaderMobile extends Component<Props, State> {
  state = { open: false };
  onFilterButtonClick = (open: boolean) => {
    this.setState({ open: open });
  };
  closeFilter = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      config: { Tidsenhet, Lønn, StatistiskMål, Fullført },
    } = this.props;
    let Modal = null;
    if (!this.props.config.Lønn) return null;

    if (this.state.open)
      Modal = (
        <ClickOutsideListener
          onOutsideClick={this.closeFilter}
          className={`${styles.visualizationheader_container_modal}`}
        >
          <div
            className={`${styles.visualizationheader_container_modal_header}`}
            onClick={() => this.onFilterButtonClick(false)}
          >
            <div
              className={`${styles.visualizationheader_container_modal_header_title}`}
            >
              <span
                className={`${styles.visualizationheader_container_modal_header_title__desc}`}
              >
                <Translate nb="Skjul visningsalternativer" />
              </span>
              <div
                className={`${styles.visualizationheader_container_modal_header_icon}`}
                // onClick={() => this.onFilterButtonClick(false)}
              />
            </div>
          </div>
          <UtdanningLonnHeaderFilters
            config={this.props.config}
            onFilterClicked={this.props.onFilterClicked}
            showHelpText={true}
          />
        </ClickOutsideListener>
      );

    return (
      <div>
        <div className={`${styles.visualizationheader_container}`}>
          <div className={`${styles.visualizationheader_container_header}`} />
          <div
            className={`${styles.visualizationheader_container_header__title}`}
          >
            <h2>
              <Translate nb="Lønn" />{" "}
            </h2>

            <span
              className={`${styles.visualizationheader_container_header__title_filter}`}
            >
              <ul>
                (
                <li>
                  {Fullført === "A" ? (
                    <span>
                      <Translate nb="Alle" />
                    </span>
                  ) : Fullført === "04" ? (
                    <span>
                      <Translate nb="0-4 år etter fullført utdanning" />
                    </span>
                  ) : (
                    <span>
                      <Translate nb="5 år eller mer etter fullført utdanning" />
                    </span>
                  )}
                  {","}
                </li>
                <li>
                  {LonnTranslations[Lønn]}
                  {","}
                </li>
                <li>
                  {StatistiskMålTranslations[StatistiskMål]}
                  {","}
                </li>
                <li>
                  {TidsenhetTranslations[Tidsenhet]}
                  {","}
                </li>
                {this.props.date && this.props.date.length > 0 && (
                  <li>
                    {this.props.date}
                    {","}
                  </li>
                )}
                {this.props.source && this.props.source.length > 0 && (
                  <li>
                    <a
                      href={"https://ssb.no/tabell/" + this.props.source}
                      title="lenke til Statistisk sentralbyrås statistikkbank"
                    >
                      Kilde: <p className={styles.dataSource}>SSB</p>
                    </a>
                  </li>
                )}
                )
              </ul>
            </span>
            {/* <span
              className={`${styles.visualizationheader_container_header__title_icon
                } ${widget
                  ? ""
                  : styles.visualizationheader_container_header__title_icon_non_widget
                }`}
              onClick={() => this.onFilterButtonClick(true)}
            /> */}
            {!this.state.open && (
              <div
                className={`${styles.visualizationheader_container_filtercontainer}`}
                onClick={() => this.onFilterButtonClick(true)}
              >
                <div
                  className={`${styles.visualizationheader_container_filtercontainer_content}`}
                >
                  <div
                    className={`${styles.visualizationheader_container_filtercontainer_content_infotext}`}
                  >
                    <Translate nb="Visningsalternativer" />
                  </div>
                  <div
                    className={`${styles.visualizationheader_container_filtercontainer_content_icon}`}
                  >
                    {this.state.open ? <CloseIcon2 /> : "+"}
                  </div>
                </div>
              </div>
            )}
          </div>
          {Modal}
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default UtdanningLonnHeaderMobile;
