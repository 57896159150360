import React from "react";
import { SammenligningTemplate } from "./index";
import { ArbeidsmarkedUtdanningElement } from "../../data/ApiTypes";
import ArbeidsledighetWrapper from "../visualizations/Arbeidsledighet/ArbeidsledighetWrapper";
import { TranslateString } from "../app/Translate";
import EntreprenorskapWrapper from "../visualizations/Entreprenorskap/EntreprenorskapWrapper";
import LenkeVisualizationWrapper from "../visualizations/Lenke/LenkeVisualizationWrapper";
import UtdanningLonnWrapper from "../visualizations/UtdanningLonn/UtdanningLonnWrapper";
import SektorWrapper from "../visualizations/Sektor/SektorWrapper";

import ComparisonPageVisualization from "../ui/ComparisonPageVisualization";

const Utdanning: SammenligningTemplate[] = [
  {
    title: "Lønn",
    widget_id: "utdanninglonn",
    path: "/sammenligning/lonn",
    Component: UtdanningLonnWrapper,
  },
  {
    title: "Arbeidsledighet",
    widget_id: "arbeidsledighet",
    path: "/sammenligning/arbeidsledighet",
    Component: ArbeidsledighetWrapper,
  },

  {
    title: "Arbeidsmarked",
    description: TranslateString("Hva er det vanligste yrkene for de som har denne utdanningen? Tall fra høsten 2023. Kilde SSB"),
    widget_id: "arbeidsmarkedUtdanning",
    path: "/sammenligning/utdanning2yrke",
    render: (
      element: ArbeidsmarkedUtdanningElement[],
      layout,
      disaggregate,
      nus_kortnavn
    ) => {
      // return (
      //   <ComparisonPageVisualization uno_id={element} direction="utdanning2yrke"></ComparisonPageVisualization>
      // )
      return (
        <ComparisonPageVisualization
          uno_id={element}
          direction="uno_id2styrk08"
          layout={layout}
          disaggregate={disaggregate}
          nus_kortnavn={nus_kortnavn}
        />
      );
    },
  },
  {
    title: "Selvstendig næringsdrivende",
    widget_id: "entrepenorskap",
    path: "/sammenligning/entrepenorskap",
    Component: EntreprenorskapWrapper,
  },
  {
    title: "Sektorer",
    widget_id: "sektor",
    path: "/sammenligning/main",
    Component: SektorWrapper,

    // render: (element: MainElement) => {
    //   const SektorConfig: SektorConfig = {
    //     values: [
    //       {
    //         label: TranslateString("Ikke i arbeid"),
    //         value: "sektor_antall_ikkearbeid",
    //       },
    //       { label: TranslateString("Privat"), value: "sektor_antall_privat" },
    //       {
    //         label: TranslateString("Offentlig"),
    //         value: "sektor_antall_offentlig",
    //       },
    //       {
    //         label: TranslateString("Arbeidsledig"),
    //         value: "sektor_antall_arbeidsledig",
    //       },
    //       {
    //         label: TranslateString("I utdanning"),
    //         value: "sektor_antall_iutdanning",
    //       },
    //       {
    //         label: TranslateString("Selvstendig næringsdriver"),
    //         value: "sektor_antall_selvstendig",
    //       },
    //     ],
    //     chartType: "pie",
    //   };

    //   let data = SektorConfig.values.map(s => {
    //     return (element as any)[s.value];
    //   });
    //   if (data.every(d => !d)) return <NoData />;
    //   return SektorConfig.chartType === "pie" ? (
    //     <PieChart values={SektorConfig.values} element={element} />
    //   ) : (
    //       <BarChart values={SektorConfig.values} element={element} />
    //     );
    // },
  },
  {
    title: "Lenke",
    widget_id: "lenke",
    path: "/sammenligning/main",
    Component: LenkeVisualizationWrapper,
  },
];
export default Utdanning;
