import React, { Component } from "react";
import Translate from "../../app/Translate";
import styles from "../Shared/HeaderFilterDesktop.module.scss";
import CloseIcon2 from "../Generic/CloseIcon2";
import { VisualizationHeaderConfigLonn } from "./VisualizationHeaderLonn";
import HeaderLonnFilters from "./HeaderLonnFilters";
import ClickOutsideListener from "../../utils/ClickOutsideListner";

type Props = {
  config: VisualizationHeaderConfigLonn;
  onFilterClicked: (event: any, key: string) => void;
};

type State = {
  expanded: boolean;
};

class LonnHeaderFilterDesktop extends Component<Props, State> {
  state = { expanded: false };

  toggleExpansion = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  closeExpansion = () => {
    this.setState({ expanded: false });
  };

  render() {
    const { config, onFilterClicked } = this.props;
    const containerContent = (
      <div className={`${styles.container_content}`}>
        <HeaderLonnFilters
          config={config}
          onFilterClicked={onFilterClicked}
          showHeaders={true}
          showHeaderHelpText={true}
          showHelpText={true}
        />
      </div>
    );

    return (
      <ClickOutsideListener
        onOutsideClick={this.closeExpansion}
        className={`${styles.container} ${
          this.state.expanded ? styles.expanded : ""
        }`}
      >
        {/* <div
          className={`${styles.container_head}`}
          onClick={this.toggleExpansion}
        > */}
        <div className={styles.container_filtercontainer}>
          <div className={`${styles.container_filtercontainer_divContainer}`}>
            <button
              aria-label="åpne/lukk"
              className={`${styles.container_filtercontainer_content} ${
                this.state.expanded
                  ? styles.container_filtercontainer_content_expanded
                  : ""
              }`}
              onClick={this.toggleExpansion}
            >
              <div
                className={`${styles.container_filtercontainer_content_head_infotext}`}
              >
                <Translate
                  nb={
                    this.state.expanded
                      ? "Skjul visningsalternativer"
                      : "Visningsalternativer"
                  }
                />
              </div>
              <div
                className={`${styles.container_filtercontainer_content_head_icon}`}
              >
                {this.state.expanded ? <CloseIcon2 /> : "+"}
              </div>
            </button>
          </div>
        </div>
        {/* </div> */}
        {this.state.expanded ? containerContent : null}
      </ClickOutsideListener>
    );
  }
}

export default LonnHeaderFilterDesktop;
