import React, { Component } from "react";
import styles from "./InfoPane.module.scss";

type Props = {
  description: string;
};

class InfoPane extends Component<Props> {
  handleExpandEvent = (e: any) => {
    if (e.target && e.target.id === "about-button") {
      e.target.innerText = "Lukk";
      e.target.classList.add(`${styles.closeabout}`);
    }
  };

  handleCollapseEvent = (e: any) => {
    if (e.target && e.target.id === "about-button") {
      e.target.innerText = "Om Sammenlign";
      e.target.classList.remove(`${styles.closeabout}`);
    }
  };

  componentDidMount() {
    $(document).on(
      "shown.bs.tab",
      'a[data-toggle="tab"]',
      this.handleExpandEvent
    );
    $(document).on(
      "hide.bs.tab",
      'a[data-toggle="tab"]',
      this.handleCollapseEvent
    );
  }

  render() {
    const { description } = this.props;
    return (
      <div className={`${"tab-content"}`}>
        <div className={`${"tab-pane"} ${styles.container}`} id={"about-panel"}>
          <div className={`${styles.content}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: description.substr(0, description.length / 2),
              }}
            >
              {/* <Translate nb={description.substr(0, description.length / 2)} /> */}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: description.substr(description.length / 2),
              }}
            >
              {/* <Translate nb={description.substr(description.length / 2)} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoPane;
