import React, { Component } from "react";

// import styles from "./ComparisonPageVisualization.module.scss";

import VizChartOptions from "./VizChartOptions";
import ComparisonRow from "../pages/ComparisonPage/ComparisonRow";
import { SammenligningTemplate } from "../comparisonsConfig";
import IsolatedComparisonPart from "../pages/ComparisonPage/IsolatedComparisonPart";
import Translate from "../app/Translate";
import styles from "./VizChartWrapper.module.scss";
import CloseIcon2 from "../visualizations/Generic/CloseIcon2";

type Props = {
  uno_ids: string[];
  rowData: { [uno_id: string]: any };
  comparison: SammenligningTemplate;
};

type State = {
  layout: string;
  expanded: Boolean;
  disaggregate: string[] | null;
  showDisagg: Boolean;
  lastSelection: string;
};

class VizChartWrapper extends Component<Props, State> {
  state: State = {
    layout: "bars",
    expanded: false,
    disaggregate: null,
    showDisagg: true,
    lastSelection: "all",
  };

  clickHandler = (e: any) => {
    if (e.target.attributes[2].nodeValue) {
      if (e.target.attributes[2].nodeValue === "all") {
        this.setState({ disaggregate: null, lastSelection: "all" });
      } else if (e.target.attributes[2].nodeValue === "gender") {
        const disaggregationValues = [
          "antall_kvinner",
          "antall_menn",
          "antall_ukjent_kjonn",
        ];
        this.setState({
          disaggregate: disaggregationValues,
          lastSelection: "gender",
        });
      } else if (e.target.attributes[2].nodeValue === "40") {
        const disaggregationValues = ["antall_40", "over_40", "40"];
        this.setState({
          disaggregate: disaggregationValues,
          lastSelection: "40",
        });
      } else if (e.target.attributes[2].nodeValue === "sector") {
        const disaggregationValues = [
          "antall_offentlig",
          "antall_privat",
          "antall_ukjent_sektor",
          "sector",
        ];
        this.setState({
          disaggregate: disaggregationValues,
          lastSelection: "sector",
        });
      } else if (e.target.attributes[2].nodeValue === "experience") {
        const disaggregationValues = [
          "antall_13",
          "antall_710",
          "other_experience",
          "experience",
        ];
        this.setState({
          disaggregate: disaggregationValues,
          lastSelection: "experience",
        });
      } else {
        if (e.target.attributes[2].nodeValue === "tree")
          this.setState({
            layout: e.target.attributes[2].nodeValue,
            showDisagg: false,
            lastSelection: "Tre",
          });
        else
          this.setState({
            layout: e.target.attributes[2].nodeValue,
            showDisagg: true,
            lastSelection: "bars",
          });
      }
    }
  };

  toggleExpansion = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { uno_ids, rowData, comparison } = this.props;
    const values = ["all", "gender", "40", "sector", "experience"];
    const containerContent = (
      <div className={`${styles.containerContent}`}>
        <div className={`${styles.optionsFirst}`}>
          <ul>
            <Translate nb="Vis som"></Translate>
            <VizChartOptions
              type={["diagramtype"]}
              widget_id={comparison.widget_id}
              val={["bars", "tree"]}
              text={["Stolper", "Tre"]}
              changeHandler={this.clickHandler}
              lastSelection={this.state.lastSelection}
            ></VizChartOptions>
          </ul>
        </div>
        {this.state.showDisagg && (
          <div className={`${styles.optionsSecond}`}>
            <ul>
              <Translate nb="Vis også (?)"></Translate>
              {/* <VizChartOptions type="disaggregate" widget_id={comparison.widget_id} val={["all", "disaggregate",""]} text={["Alle", "Menn/Kvinner", "Under 40/Over 40", "Sektor", "Erfaring"]} changeHandler={this.clickHandler} lastSelection={this.state.lastSelection}></VizChartOptions> */}
              <VizChartOptions
                type={values}
                widget_id={comparison.widget_id}
                val={values}
                text={[
                  "Alle",
                  "Menn/Kvinner",
                  "Under 40 år/Over 40 år",
                  "Sektor",
                  "Erfaring",
                ]}
                changeHandler={this.clickHandler}
                lastSelection={this.state.lastSelection}
              ></VizChartOptions>
            </ul>
          </div>
        )}
      </div>
    );
    let containerStyle = this.state.expanded
      ? styles.buttonContent_expanded
      : null;
    return (
      <React.Fragment>
        <button
          aria-label="åpne/lukk"
          className={`${styles.buttonContainer} ${
            this.state.expanded ? styles.expanded : ""
          }`}
          onClick={this.toggleExpansion}
        >
          {/* <div className={`${styles.header}`}><Translate nb="Se visningsalternativer" /></div>
                    <div className={`${styles.head_icon}`}>
                        {this.state.expanded ? <CloseIcon2 /> : <OpenIcon />}
                    </div> */}
          <div className={`${styles.buttonContent} ${containerStyle}`}>
            <div className={`${styles.header}`}>
              {this.state.expanded ? (
                <Translate nb="Skjul visningsalternativer" />
              ) : (
                <Translate nb="Visningsalternativer" />
              )}
            </div>
            <div className={`${styles.head_icon}`}>
              {this.state.expanded ? <CloseIcon2 /> : "+"}
            </div>
          </div>
        </button>
        {this.state.expanded ? containerContent : null}
        <ComparisonRow>
          {uno_ids.map((uno_id) => (
            <IsolatedComparisonPart
              key={uno_id}
              uno_idsz={uno_id}
              data={rowData[uno_id]}
              template={comparison}
              widget={false}
              layout={this.state.layout}
              disaggregate={this.state.disaggregate}
            />
          ))}
        </ComparisonRow>
      </React.Fragment>
    );
  }
}

export default VizChartWrapper;
