import React, { Component } from "react";
import RadioButtonGroup from "../../defaultComponents/RadioButtonGroup";
import Translate from "../../app/Translate";
import styles from "../Shared/VisualizationFilterHeader.module.scss";
import { UtdanningLonnConfig } from "./UtdanningLonnConfig";

type Props = {
  config: UtdanningLonnConfig;
  onFilterClicked: (event: any, key: string) => void;
  showHeaders?: boolean;
  showHelpText?: boolean;
  showHeaderHelpText?: boolean;
};

type State = {
  shownHelpText: string;
};

class UtdanningLonnHeaderFilters extends Component<Props, State> {
  state = { shownHelpText: "" };

  onClickHelpText = (type: string) => {
    if (this.state.shownHelpText === type) {
      this.setState({ shownHelpText: "" });
      return;
    }

    this.setState({ shownHelpText: type });
  };
  handleKeyUp = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Escape") {
      this.onClickHelpText("");
    }
  };

  render() {
    const { Tidsenhet, Lønn, StatistiskMål, Fullført } = this.props.config;
    const { showHelpText, showHeaders, showHeaderHelpText } = this.props;
    return (
      <div className={`${styles.visualizationheader_container_modal_filters}`}>
        <ul>
          {showHeaders ? (
            <div
              className={
                styles.visualizationheader_container_modal_filters_header
              }
            >
              <Translate nb="Antall år etter fullført utdanning" />
              {showHeaderHelpText ? (
                <span
                  className={
                    styles.visualizationheader_container_modal_filters_header_icon
                  }
                  tabIndex={0}
                  onMouseOver={() => this.onClickHelpText("Fullført")}
                  onMouseOut={() => this.onClickHelpText("")}
                  onFocus={() => this.onClickHelpText("Fullført")}
                  onBlur={() => this.onClickHelpText("")}
                  onKeyDown={this.handleKeyUp}
                >
                  <span
                    className={
                      styles.visualizationheader_container_modal_filters_header_icon_inner
                    }
                  >
                    ?
                  </span>
                  {this.state.shownHelpText === "Fullført" ? (
                    <div
                      className={
                        styles.visualizationheader_container_modal_filters_header_helptext
                      }
                    >
                      <div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_content
                        }
                      >
                        <Translate nb="Vis bruttolønn beregnet på grunnlag av antall år etter fullført utdanning." />
                      </div>
                      {/*<div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_closeIcon
                        }
                        onClick={() => this.onClickHelpText("")}
                      >
                        <CloseIcon />
                      </div>*/}
                    </div>
                  ) : null}
                </span>
              ) : null}
            </div>
          ) : null}
          <RadioButtonGroup
            group={[
              {
                text: <Translate nb="Alle" />,
                selected: Fullført === "A",
                valueKey: "A",
                helptext: showHelpText ? (
                  <Translate nb="Viser tall for alle med utdanningen, uavhengig av hvor lenge det er siden de har fullført." />
                ) : undefined,
              },
              {
                text: <Translate nb="0-4 år" />,
                selected: Fullført === "04",
                valueKey: "04",
                helptext: showHelpText ? (
                  <Translate nb="Viser lønn for bare de som har fullført utdanningen de siste 4 år." />
                ) : undefined,
              },
              {
                text: <Translate nb="5 år eller mer" />,
                selected: Fullført === "5",
                valueKey: "5",
                helptext: showHelpText ? (
                  <Translate nb="Viser lønn for de som har fullført utdanningen for 5 år siden eller mer." />
                ) : undefined,
              },
            ]}
            onChange={(event) => this.props.onFilterClicked(event, "Fullført")}
          />
        </ul>
        <ul>
          {showHeaders ? (
            <div
              className={
                styles.visualizationheader_container_modal_filters_header
              }
            >
              <Translate nb="Grunnlag" />
              {showHeaderHelpText ? (
                <span
                  className={
                    styles.visualizationheader_container_modal_filters_header_icon
                  }
                  tabIndex={0}
                  onMouseOver={() => this.onClickHelpText("Grunnlag")}
                  onMouseOut={() => this.onClickHelpText("")}
                  onFocus={() => this.onClickHelpText("Grunnlag")}
                  onBlur={() => this.onClickHelpText("")}
                  onKeyDown={this.handleKeyUp}
                >
                  <span
                    className={
                      styles.visualizationheader_container_modal_filters_header_icon_inner
                    }
                  >
                    ?
                  </span>
                  {this.state.shownHelpText === "Grunnlag" ? (
                    <div
                      className={
                        styles.visualizationheader_container_modal_filters_header_helptext
                      }
                    >
                      <div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_content
                        }
                      >
                        <Translate nb="Vis lønn utbetalt eller avtalt lønn uten tillegg på grunn av arbeid på søndag, feriedag, skift eller turnus." />
                      </div>
                      {/*<div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_closeIcon
                        }
                        onClick={() => this.onClickHelpText("")}
                      >
                        <CloseIcon />
                      </div>*/}
                    </div>
                  ) : null}
                </span>
              ) : null}
            </div>
          ) : null}
          <RadioButtonGroup
            group={[
              {
                text: <Translate nb="Lønn" />,
                selected: Lønn === "Brutto",
                valueKey: "Brutto",
                helptext: showHelpText ? (
                  <Translate nb="Lønn er bruttolønn." />
                ) : undefined,
              },
              {
                text: <Translate nb="Kun avtalt lønn" />,
                selected: Lønn === "Med overtid",
                valueKey: "Med overtid",
                helptext: showHelpText ? (
                  <Translate nb="Avtalt lønn uten tillegg på grunn av arbeid på søndag, feriedag, skift eller turnus." />
                ) : undefined,
              },
            ]}
            onChange={(event) => this.props.onFilterClicked(event, "Lønn")}
          />
        </ul>
        <ul>
          {showHeaders ? (
            <div
              className={
                styles.visualizationheader_container_modal_filters_header
              }
            >
              <Translate nb="Beregning" />
              {showHeaderHelpText ? (
                <span
                  className={
                    styles.visualizationheader_container_modal_filters_header_icon
                  }
                  tabIndex={0}
                  onMouseOver={() => this.onClickHelpText("Beregning")}
                  onMouseOut={() => this.onClickHelpText("")}
                  onFocus={() => this.onClickHelpText("Beregning")}
                  onBlur={() => this.onClickHelpText("")}
                  onKeyDown={this.handleKeyUp}
                >
                  <span
                    className={
                      styles.visualizationheader_container_modal_filters_header_icon_inner
                    }
                  >
                    ?
                  </span>
                  {this.state.shownHelpText === "Beregning" ? (
                    <div
                      className={
                        styles.visualizationheader_container_modal_filters_header_helptext
                      }
                    >
                      <div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_content
                        }
                      >
                        <Translate nb="Vis beregning av tall gitt av median / gjennomsnitt / median og kvartiler." />
                      </div>
                      {/*<div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_closeIcon
                        }
                        onClick={() => this.onClickHelpText("")}
                      >
                        <CloseIcon />
                      </div>*/}
                    </div>
                  ) : null}
                </span>
              ) : null}
            </div>
          ) : null}
          <RadioButtonGroup
            group={[
              {
                text: <Translate nb="Median" />,
                selected: StatistiskMål === "Median",
                valueKey: "Median",
                helptext: showHelpText ? (
                  <Translate nb="Vis median bruttolønn. Medianen finner vi ved først å sortere lønnen til alle personer i denne kategorien fra lavest til høyest, og finne det midterste tallet." />
                ) : undefined,
              },
              {
                text: <Translate nb="Gjennomsnitt" />,
                selected: StatistiskMål === "Gjennomsnitt",
                valueKey: "Gjennomsnitt",
                helptext: showHelpText ? (
                  <Translate nb="Vis gjennomsnitt for bruttolønn." />
                ) : undefined,
              },
              {
                text: <Translate nb="Median og kvartiler" />,
                selected: StatistiskMål === "Median og kvartiler",
                valueKey: "Median og kvartiler",
                helptext: showHelpText ? (
                  <Translate nb="Vis median og kvartiler for bruttolønn. Dette finner vi ved å sortere lønnen til alle personer i denne kategorien fra lavest til høyest, så dele i fire like store grupper." />
                ) : undefined,
              },
            ]}
            onChange={(event) =>
              this.props.onFilterClicked(event, "StatistiskMål")
            }
          />
        </ul>
        <ul>
          {showHeaders ? (
            <div
              className={
                styles.visualizationheader_container_modal_filters_header
              }
            >
              <Translate nb="Periode" />
              {showHeaderHelpText ? (
                <span
                  className={
                    styles.visualizationheader_container_modal_filters_header_icon
                  }
                  tabIndex={0}
                  onMouseOver={() => this.onClickHelpText("Periode")}
                  onMouseOut={() => this.onClickHelpText("")}
                  onFocus={() => this.onClickHelpText("Periode")}
                  onBlur={() => this.onClickHelpText("")}
                  onKeyDown={this.handleKeyUp}
                >
                  <span
                    className={
                      styles.visualizationheader_container_modal_filters_header_icon_inner
                    }
                  >
                    ?
                  </span>
                  {this.state.shownHelpText === "Periode" ? (
                    <div
                      className={
                        styles.visualizationheader_container_modal_filters_header_helptext
                      }
                    >
                      <div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_content
                        }
                      >
                        <Translate nb="Vis sum lønn utbetalt enten årlig, månedlig eller per time." />
                      </div>
                      {/*<div
                        className={
                          styles.visualizationheader_container_modal_filters_header_helptext_closeIcon
                        }
                        onClick={() => this.onClickHelpText("")}
                      >
                        <CloseIcon />
                      </div>*/}
                    </div>
                  ) : null}
                </span>
              ) : null}
            </div>
          ) : null}
          <RadioButtonGroup
            group={[
              {
                text: <Translate nb="Per år" />,
                selected: Tidsenhet === "Årlig",
                valueKey: "Årlig",
                helptext: showHelpText ? (
                  <Translate
                    nb="Viser sum lønn utbetalt i året."
                    nn="Viser sum lønn utbetalt i året."
                  />
                ) : undefined,
              },
              {
                text: <Translate nb="Per måned" />,
                selected: Tidsenhet === "Månedlig",
                valueKey: "Månedlig",
                helptext: showHelpText ? (
                  <Translate
                    nb="Viser sum lønn utbetalt månedlig."
                    nn="Viser sum lønn utbetalt månedlig."
                  />
                ) : undefined,
              },
              {
                text: <Translate nb="Ca. timelønn" />,
                selected: Tidsenhet === "Ca. timelønn",
                valueKey: "Ca. timelønn",
                helptext: showHelpText ? (
                  <Translate
                    nb="Viser omtrent hva timelønn vil tilsvare hvis en jobber normal arbeidstid, 37,5 timer pr uke"
                    nn="Viser omtrent hva timelønn vil tilsvare hvis en jobber normal arbeidstid, 37,5 timer pr uke"
                  />
                ) : undefined,
              },
            ]}
            onChange={(event) => this.props.onFilterClicked(event, "Tidsenhet")}
          />
        </ul>
      </div>
    );
  }
}

export default UtdanningLonnHeaderFilters;
